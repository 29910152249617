import { BaseSerializer } from '../Base/Base.serializer';
import { Serializer } from '../Base/Serializer';
import { CategoriesModel } from '../../models/Global-Module/categories.model';


export class CategoriesSerializer extends BaseSerializer implements Serializer {

    fromJson(json: any): CategoriesModel {
        return this.adapt(json.payload);
    }

    fromJsonList(json: any): [] {
        return this.adaptList(json.payload, json.pagination);
    }

    toJson(model: CategoriesModel): any {
        console.log(model);
        let category_object = {
            '_id': model._id,
            'name': model.name,
            'image': model.image,

        };

        return category_object;
    }

    toFormData(object: any): FormData {
        const formData = new FormData();
		formData.append('name' , object.name );
		formData.append('image', object.image);
		return formData;
    }

    public adapt(item: any): CategoriesModel {
        let object = new CategoriesModel(item._id);
        object.name = item.name;
        object.image = item.image;

        return object;
    }

    status(status): any {

        return {
            'is_active': status,
        };
    }

}
