import {BaseSerializer} from '../Base/Base.serializer';
import {Serializer} from '../Base/Serializer';
import {CmsUsersModel} from '../../models/User-Module/cms.users.model';


export class CmsUsersSerializer extends BaseSerializer implements Serializer {

	fromJson(json: any): CmsUsersModel {
		return this.adapt(json.body);
	}

	fromJsonList(json: any): [] {
		return this.adaptList(json.body, json.pagination);
	}

	toJson(model: CmsUsersModel): any {
		if (model.password){
			return {
				'_id' : model._id,
				'email' : model.email,
				'is_active' : model.is_active,
				'name' : model.name,
				'password' : model.password,
				'roles' : model.roles,
				"phone" : model.phone,
			};
		}
		return {
			'_id' : model._id,
			'email' : model.email,
			'is_active' : model.is_active,
			'name' : model.name,
		    'phone' : model.phone,
			'trips': model.trips,
			'favorite_stations': model.favorite_stations,
			'vehicles': model.vehicles,
			'bookings': model.bookings
		};
	}

	toFormData(object: any): FormData {
		return null;
	}

	public adapt(item: any): CmsUsersModel {
		let model =  new CmsUsersModel(item._id);
		model.name = item.name;
		model.email = item.email;
		model.is_active = item.is_active;
		model.phone = item.phone ? item.phone : null;
		model.trips = item.trips ? item.trips : null;
		model.favorite_stations = item.favorite_stations ? item.favorite_stations : null;
		model.roles = item.roles ? item.roles.map(a => a.id) : null;
		model.vehicles = item.vehicles ? item.vehicles : null;
		model.bookings = item.bookings ? item.bookings : null;
		model.user_name = item.name;
		return  model;
	}

	status(status): any {

		return {
			'is_active' : status,
		};
	}

}
