import {ModelBase} from '../Base/base.model';
import { MultiLanguageModel } from '../Base/multi.language.model';

export class CmsUsersModel extends ModelBase {

	public name: MultiLanguageModel;
	public user_name: string;
	public email: string;
	public password: string;
	public is_active: boolean;

	public phone:number;
	public roles: number[];

	public trips: {
		id: number,
		name: string,
		from_location: string,
		to_location:string,
		from_latitude:string,
		from_longitude:string,
		to_latitude: string,
		to_longitude: string,
	}[];

	public favorite_stations: {
		id: number,
		name: string,
	}[];

	public vehicles: {
		id: number,
		comapny: string,
		model: string,
		plate_number: string,
		color: string,
		manufacture_year: string,
		is_default: boolean,
	}[];

	public bookings: {
		id: number,
		station: object,
		plug_type: object,
		kws: number,
		price: number,
		date: string,
		from: string,
		to:string
	}[];

	constructor(_id: string) {
		super(_id);
		this.createObjects();
	}

	public list() {
		return {
			'id': this._id,
			'name' : this.name,
			'email' : this.email,
			'is_active': this.is_active,
			'phone': this.phone,
			'trips':this.trips,
			'favorite_stations': this.favorite_stations,
			'vehicles': this.vehicles,
			'bookings' : this.bookings,
			'user_name' : this.user_name,
		};
	}

	public createObjects() {
		this.name = new MultiLanguageModel();
	}
}
