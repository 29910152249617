import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseService } from '../Base/base.service';
import { environment } from '../../../../environments/environment';
import { CategoriesSerializer } from '../../Serializers/Global-Module/categories.serializer';
import { CategoriesModel } from '../../models/Global-Module/categories.model';


@Injectable({
    providedIn: 'root'
})

export class CategoriesService extends BaseService<CategoriesModel> {
    constructor(Http: HttpClient) {
        super(
            Http,
            environment.url('v1'),
            'admin/categories',
            new CategoriesSerializer());
    }

    prepareObject(model: CategoriesModel, controls) {

        model.name = controls['name'].value;
        model.image = controls['image'].value;
        return model;
    }

}
