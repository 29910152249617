import { ModelBase } from '../Base/base.model';

export class CategoriesModel extends ModelBase {

    public name: string;
    public image: string;

    constructor(_id: string) {
        super(_id);
    }

    public list() {
        return {
            '_id': this._id,
            'name': this.name,
            'image': this.image,
        }
        
    }
}
