export const permissions = {
    ADMINS_STATIONS_STORE:'ADMINS_STATIONS_STORE',
    ADMINS_STATIONS_UPDATE:'ADMINS_STATIONS_UPDATE',
    ADMINS_STATIONS_DESTROY:'ADMINS_STATIONS_DESTROY',
    ADMINS_COUNTRIES_UPDATE:'ADMINS_COUNTRIES_UPDATE',
    ADMINS_COUNTRIES_DESTROY:'ADMINS_COUNTRIES_DESTROY',
    ADMINS_COUNTRIES_STORE:'ADMINS_COUNTRIES_STORE',
    ADMINS_ROLES_STORE:'ADMINS_ROLES_STORE',
    ADMINS_ROLES_UPDATE:'ADMINS_ROLES_UPDATE',
    ADMINS_ROLES_DESTROY:'ADMINS_ROLES_DESTROY',
    ADMINS_DISTRICTS_STORE:'ADMINS_DISTRICTS_STORE',
    ADMINS_DISTRICTS_UPDATE:'ADMINS_DISTRICTS_UPDATE',
    ADMINS_DISTRICTS_DESTROY:'ADMINS_DISTRICTS_DESTROY',
    ADMINS_NETWORKS_STORE:'ADMINS_NETWORKS_STORE',
    ADMINS_NETWORKS_UPDATE:'ADMINS_NETWORKS_UPDATE',
    ADMINS_NETWORKS_DESTROY:'ADMINS_NETWORKS_DESTROY',
    ADMINS_FACILITIES_STORE:'ADMINS_FACILITIES_STORE',
    ADMINS_FACILITIES_UPDATE:'ADMINS_FACILITIES_UPDATE',
    ADMINS_FACILITIES_DESTROY:'ADMINS_FACILITIES_DESTROY',
    ADMINS_ADMINS_STORE:'ADMINS_ADMINS_STORE',
    ADMINS_ADMINS_UPDATE:'ADMINS_ADMINS_UPDATE',
    ADMINS_ADMINS_DESTROY:'ADMINS_ADMINS_DESTROY',
    ADMINS_STATIONS_PLUG_TYPES_STORE:'ADMINS_STATIONS_PLUG_TYPES_STORE',
    ADMINS_STATIONS_PLUG_TYPES_UPDATE:'ADMINS_STATIONS_PLUG_TYPES_UPDATE',
    ADMINS_STATIONS_PLUG_TYPES_DESTROY:'ADMINS_STATIONS_PLUG_TYPES_DESTROY',
    ADMINS_USERS_SHOW:'ADMINS_USERS_SHOW',
    ADMINS_VEHICLES_STORE:'ADMINS_VEHICLES_STORE',
    ADMINS_VEHICLES_UPDATE:'ADMINS_VEHICLES_UPDATE',
    ADMINS_VEHICLES_DESTROY:'ADMINS_VEHICLES_DESTROY',
    ADMINS_MODELS_STORE:'ADMINS_MODELS_STORE',
    ADMINS_MODELS_UPDATE:'ADMINS_MODELS_UPDATE',
    ADMINS_MODELS_DESTROY:'ADMINS_MODELS_DESTROY',
    ADMINS_COMPANIES_STORE: 'ADMINS_COMPANIES_STORE',
    ADMINS_COMPANIES_UPDATE: 'ADMINS_COMPANIES_UPDATE',
    ADMINS_COMPANIES_DESTROY: 'ADMINS_COMPANIES_DESTROY',

}